.container {
  max-width: 950px;
  margin: 50px auto 0 auto;
}

.confirm {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  background-color: white;
  padding: 20px;
}