.title, .body {
  text-align: center;
  margin-bottom: 10px !important;
}

.btnContainer {
  display: flex;
}

.button {
  flex:  1 1 auto !important;
  width: 40% !important;
  margin-right: 10px !important;
  margin-left: 10px !important;
}