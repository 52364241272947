.text {
  text-align: center;
  font-size: 25px !important;
  margin-top: 20px !important;
  font-weight: 700 !important;
}

.image {
  margin: 70px auto 0px auto;
  display: flex;
}
