.container {
  height: 500px;
  max-width: 300px;
  min-width: 300px;
  padding-top: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 20px auto 20px auto;
}

.heading {
  margin-bottom: 20px !important;
  font-weight: 700 !important;
  font-size: 25px !important;
}
