.primary,
.secondary,
.tertiary {
  margin-top: 15px !important;
}

.primary {
  background-color: #A01820 !important;
  color: #fff !important;
}

.secondary {
  background-color: #2c2c2c !important;
  color: #fff !important;
}

.tertiary {
  background-color: transparent !important;
  border: none;
  color: #A01820 !important;
}
