.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  background-color: white;
  padding: 20px;
}

.startTime {
  margin-right: 7px !important;
}

.startTime, .endTime {
  width: 49% !important;
}

.btnContainer {
  display: flex;
}

.button {
  flex:  1 1 auto !important;
  width: 40% !important;
  margin-right: 10px !important;
  margin-left: 10px !important;
}