.container {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 50px;
}

.card {
  height: 420px;
  max-width: 200px;
  min-width: 200px;
  padding-top: 20px;
  text-align: center;
  margin: 0;
  float: left;
}

.title {
  text-align: center;
  margin-left: 110px;
}

.event {
  text-decoration: underline;
}

.btnContainer {
  width: 99% !important;
  max-width: 990px;
  margin: 10px auto;
}

.previous {
  float: left !important;
}

.next {
  float: right !important;
}