.content {
  height: 100%;
}

.icon {
  margin-bottom: 40px;
}

.button{
  font-size: 30px !important;
  font-weight: 700 !important;
  width: 175px !important;
  margin: 0px 0px 15px 0px !important;
}

.headings{
  font-size: 30px !important;

  margin-bottom: -10xp !important;
}