.primary {
  color: #A01820;
}

.secondary {
  color: #2c2c2c;
}

.tertiary {
  color: green;
}
