.container {
  height: 250px;
  max-width: 300px;
  min-width: 300px;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 20px auto 20px auto;
}

.title, .admin {
  font-weight: 700 !important;
  font-size: 25px !important;
  margin: 10px 0px 10px 0px !important;
}

.admin {
  text-decoration: underline;
}