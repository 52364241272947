.content {
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center';
}

.title {
  text-align: center;
}

.form {
  width: '100%';
}

.error, .message {
  margin: 15px !important;
  text-align: center;
}

.error {
  color: red;
}