.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  padding: 20px;
}

.title, .button {
  font-weight: 700 !important;
}

.title, .body {
  text-align: center;
  margin-bottom: 15px !important;
}

.body {
  color: black;
}

.button {
  display: flex !important;
  margin: 0 auto !important;
  font-size: 18px !important;
}