.tabBar {
  width: 100%;
  position: fixed;
  bottom: 0;
  margin-top: 20px !important;
  padding-bottom: env(safe-area-inset-bottom, 0);
}

.selected:hover,
.selected:focus {
  color: #A01820 !important;
}
